<template>
  <div class="narive-list">
    <div>
      <span>籍贯信息</span>
      <el-divider></el-divider>
    </div>
    <div>
      <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false"
        @close="handleClose(tag)">
        {{tag}}
      </el-tag>
      <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
        @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加</el-button>
    </div>
  </div>
</template>

<script>
import { getCycNativePlace, setCycNativePlace } from "../api/native-config";
export default {
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
    };
  },
  created() {
    getCycNativePlace()
      .then((res) => {
        this.dynamicTags = res.data;
      })
      .catch((err) => {});
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      setCycNativePlace({ config: this.dynamicTags })
        .then((res) => {})
        .catch((err) => {});
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
        setCycNativePlace({ config: this.dynamicTags })
          .then((res) => {})
          .catch((err) => {});
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>

<style scoped lang="scss">
.narive-list {
  height: 520px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>