import api from "../../../base/utils/request";

export const setCycNativePlace = data => {
    return api({
        url: '/admin/cyc/Settings/setCycNativePlaceConfig',
        method: 'post',
        data
    })
}

export const getCycNativePlace = data => {
    return api({
        url: '/admin/cyc/Settings/getCycNativePlaceConfig',
        method: 'post',
        data
    })
}